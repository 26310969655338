<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('admincenters.createcenter')}}

                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                                li.breadcrumb-item
                                    router-link(to="/pages/home") {{ $t('headers.dashboard')}}
                                li.breadcrumb-item
                                    router-link(to="/pages/dashboardCenter") {{ $t('headers.centers')}}
                                li.breadcrumb-item.active(aria-current="page") {{ $t('admincenters.createcenter')}}

                div.row.mt-5.pb-5.d-flex.align-items-center
                    div.col-lg-6.col-md-6.layout-spacing.mx-auto
                        div.statbox.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                            h4 {{ $t('admincenters.complet')}}
                                vue-form(:state="formstate")
                                    div.widget-content.widget-content-area
                                        div.form-group.mb-4
                                            label(for="") {{ $t('admincenters.parentcenter')}}
                                            select.form-control(id="exampleFormControlSelect1" @change="getCenterList" v-model="model.idParentCenter")
                                                option(value="0" selected) {{ $t('headers.select')}}
                                                option(:value=" Center.idCenter" v-for="Center in CenterList") {{ Center.sCenterName }}
                                        
                                        div.form-group.mb-4
                                            label(for="formGroupExampleInput") {{ $t('admincenters.namecenter')}}
                                            validate(tag="div")
                                                input.form-control(type="text" required name="sCenterName" id="formGroupExampleInput" :placeholder="$t('admincenters.namecenter')"  v-model="model.sCenterName")
                                                field-messages.text-danger(name="sCenterName" )
                                                    div(slot="required") {{ $t('admincenters.requestcenter')}}
                                        div.form-group.mb-4
                                            label(for="") {{ $t('admincenters.centertype')}}
                                            select.form-control(id="exampleFormControlSelect1" @change="getCenterType" v-model="model.idCenterType")
                                                option(value="0" selected) {{ $t('headers.select')}}
                                                option(:value=" CenterType.id" v-for="CenterType in CenterTypes") {{ CenterType.option }}

                                            
                                        div.form-group.mb-4
                                            label(for="") {{ $t('admincenters.country')}}
                                            select.form-control(id="exampleFormControlSelect1" @change="getStates" v-model="idCountry")
                                                option(value="0" selected) {{ $t('headers.select')}}
                                                option(:value=" country.id" v-for="country in countries") {{ country.option }}
                                                
                                        div.form-group.mb-4
                                            label(for="") {{ $t('admincenters.state')}}
                                            select.form-control(id="exampleFormControlSelect1" v-model="model.idState")
                                                option(value="0") {{ $t('headers.select')}}
                                                option(:value="state.id" v-for="state in states") {{ state.option }}

                                        div.form-group.mb-4
                                            label(for="ForCity") {{ $t('admincenters.city')}}
                                            validate(tag="div")
                                                input.form-control(type="text" id="ForCity" required name="sCityName" :placeholder="$t('admincenters.city')" v-model="model.sCity") 
                                                field-messages.text-danger(name="sCityName" )
                                                    div(slot="required") {{ $t('admincenters.requestcity')}}

                                        button.btn.btn-secondary(type="button" @click="SaveCenter") {{ $t('btns.btnsend')}}      

</template>
<script>
/* eslint-disable */
export default {
    name: 'Centers',
    data() {
        return {
            formstate: {},
            idCountry: 0,
            countries: [],
            idCenterType: 0,
            CenterTypes: [],
            model: {
                idCenterType: 0,
                idState: 0,
                sCenterName: '',
                sCity: '',
                idParentCenter: 0
            },
            states: [],
            CenterList: []
        }
    },
    methods: {
        async getCenterList() {
            await axios.get(apiURL + 'Center/getCenterListByAdmin?idPerson=' + localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                console.log(response.data.centerList)
                this.CenterList = response.data.centerList
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },
        async getCenterType() {
            await axios.get(apiURL + 'Common/getCenterTypes')
            .then( (response) => {
                this.CenterTypes = response.data.options
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },

        async getCountries() {
            await axios.get(apiURL + 'Common/getCountries?idLanguage=2')
            .then( (response) => {
                this.countries = response.data.options
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },

        async getStates() {
            this.openLoading(this.$t('msg.openLoading'))
            await axios.get(apiURL + 'Common/getStates?idCountry=' + this.idCountry)
            .then( (response) => {
                Swal.close()
                this.states = response.data.options
            } )
            .catch( (error) => {
                Swal.close()
                console.log(error)
            } )
        },
        
        async SaveCenter() {
            if(this.formstate.$invalid) {
                return;
            }
            this.openLoading(this.$t('msg.adminSwal1a'));

            await axios.post(apiURL + 'Center/Create', this.model, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.bIsCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                Swal.fire(this.$t('msg.adminSwal1'))
                router.push('/pages/home')
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        }


    },
    mounted() {
            this.checkPermisions(this.$router.currentRoute.path)
            this.getCountries(),
            this.getCenterType(),
            this.getCenterList()
        }    
        
}
</script>